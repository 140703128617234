import React, { useState, useEffect } from "react";

const PaymentPage = () => {
  const [paymentSuccess, setPaymentSuccess] = useState(false);

  useEffect(() => {
    const key_id = 'YOUR_KEY_ID';
    const key_secret = 'YOUR_SECRET';

    // First, obtain an access token using your key_id and key_secret
    fetch('https://api.razorpay.com/v1/api/token', {
      method: 'GET',
      headers: {
        'Authorization': 'Basic ' + btoa(key_id + ':' + key_secret)
      }
    })
    .then(response => response.json())
    .then(token => {
      // Once you have the token, use it to create the order
      fetch('https://api.razorpay.com/v1/orders', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token.access_token
        },
        body: JSON.stringify({
          amount: 50000,
          currency: "INR",
          receipt: "receipt#1",
          notes: {
            key1: "value3",
            key2: "value2"
          }
        })
      })
      .then(response => response.json())
      .then(order => {
        console.log('Order created:', order);
        // After order creation, you can initiate the payment
        const options = {
          key: key_id,
          amount: order.amount,
          currency: order.currency,
          name: "Acme Corp",
          description: "Test Transaction",
          image: "https://example.com/your_logo",
          order_id: order.id,
          handler: (res) => {
            console.log(res);
            setPaymentSuccess(true);
          },
          prefill: {
            name: "Piyush Garg",
            email: "youremail@example.com",
            contact: "9999999999",
          },
          notes: {
            address: "Razorpay Corporate Office",
          },
          theme: {
            color: "#3399cc",
          },
        };
        const rzpay = new window.Razorpay(options);
        rzpay.open();
      })
      .catch(error => console.error('Error creating order:', error));
    })
    .catch(error => console.error('Error obtaining token:', error));
  }, []);

  return (
    <div>
      <h1>Payment Page</h1>
      {paymentSuccess ? (
        <div>
          <h2>Payment Successful!</h2>
          {/* You can show a success message or redirect the user */}
        </div>
      ) : (
        <h2>Processing Payment...</h2>
      )}
    </div>
  );
};

export default PaymentPage;

























// import React, { useState } from 'react'
// import { useNavigate } from 'react-router-dom';
// import { Button, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
// import './Pay.css'
// // import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
// import { doc, getDoc } from "firebase/firestore";
// import { db } from '../firebase';
// import { useCallback } from "react";
// import useRazorpay from "react-razorpay";




// const Pay = () => {

//   const navigate = useNavigate();
//   const [pid, setPid] = useState()
//   const [ptype, setPtype] = useState()

//   const handlePID = async (e, pid) => {
//     e.preventDefault()

//     const docRef = doc(db, "project", String(pid));
//     const docSnap = await getDoc(docRef);

//     if (docSnap.exists()) {
//       console.log("Document data:", docSnap.data());
//       window.amount = docSnap.data().amount
//       window.pid = typeof docSnap.data().pid
//     } else {
//       console.log("No such document!");
//     }
//   };

//   const [paymentStatus, setPaymentStatus] = useState('');
//   const { createOrder } = useRazorpay();

//   const handlePayment = async () => {
//     try {
//       fetch('https://api.razorpay.com/v1/orders', {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//           'Authorization': 'Basic ' + btoa('[YOUR_KEY_ID]:[YOUR_KEY_SECRET]')
//         },
//         body: JSON.stringify({
//           amount: 50000,
//           currency: 'INR',
//           receipt: 'receipt#1',
//           notes: {
//             key1: 'value3',
//             key2: 'value2'
//           }
//         })
//       })
//         .then(response => response.json())
//         .then(data => console.log(data))
//         .catch(error => console.error('Error:', error));

//       const options = {
//         key: 'YOUR_KEY_ID',
//         amount: '3000',
//         currency: 'INR',
//         name: 'Acme Corp',
//         description: 'Test Transaction',
//         image: 'https://example.com/your_logo', // URL to your logo
//         order_id: order.id,
//         handler: (response) => {
//           console.log('Payment response:', response);
//           // Handle payment success or failure
//         },
//         prefill: {
//           name: 'Piyush Garg',
//           email: 'youremail@example.com',
//           contact: '9999999999',
//         },
//         notes: {
//           address: 'Razorpay Corporate Office',
//         },
//         theme: {
//           color: '#3399cc',
//         },
//       };

//       const rzpay = new Razorpay(options);
//       rzpay.open();
//     } catch (error) {
//       console.error('Error processing payment:', error);
//     }
//   };

//   return (
//     <div className="pay">
//       <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
//         <form className="space-y-6" action="#" method="POST">
//           <div>
//             <center>
//               <br /> <Typography variant='h5' fontWeight={700} color={'#fff56e'}>Apptech Project Payments</Typography>
//               <hr /><br />
//               <TextField id="filled-basic" label="Project Name" variant="outlined" style={{ width: '95%' }} className='white' />
//               <br /><br />
//               <TextField label="Project Identification No." onChange={e => setPid(e.target.value)} variant="outlined" className='white' style={{ width: '95%' }} id="myamount" />
//               <br /><br />



//               <Select style={{ width: '95%', textAlign: 'left' }} labelId="demo-simple-select-label" className='white' id="demo-simple-select" label="Payment Type" defaultValue={'Select Payment Type'} onChange={e => setPtype(e.target.value)}>
//                 <MenuItem value={'Select Payment Type'}>Select Payment Type</MenuItem>
//                 <MenuItem value={'Advance Development Payment'}>Advance Development Payment</MenuItem>
//                 <MenuItem value={'Final Development Payment'}>Final Development Payment</MenuItem>
//                 <MenuItem value={'Maintainance & Hosting Payment'}>Maintainance & Hosting Payment</MenuItem>
//                 <MenuItem value={'Phase I Development Payment'}>Phase I Development Payment</MenuItem>
//                 <MenuItem value={'Phase II Development Payment'}>Phase II Development Payment</MenuItem>
//                 <MenuItem value={'Phase III Development Payment'}>Phase III Development Payment</MenuItem>
//                 <MenuItem value={'Upgradation Payment'}>Upgradation Payment</MenuItem>
//               </Select>

//               <br /><br />
//             </center>
//           </div>


//           <br /><br />
//           <div>
//             <center><Button onClick={(e) => handlePayment(e)} // startIcon={<CurrencyRupeeIcon />}
//               variant='contained' style={{ width: '60%', height: '50px' }}>
//               Confirm & Pay
//             </Button>
//             </center>
//           </div>
//         </form>
//       </div>
//     </div>
//   )
// }

// export default Pay