import { Button } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'

function Home() {
    const navigate = useNavigate()
    // VanillaTilt.init(document.querySelector("card1"), {
    //     max: 25,
    //     speed: 400,
    //     glare: true,
    //     "max-glare": 0.8
    // });
    // VanillaTilt.init(document.querySelector("#card2"), {
    //     max: 25,
    //     speed: 400,
    //     glare: true,
    //     "max-glare": 0.8
    // });
    // VanillaTilt.init(document.querySelector("#card3"), {
    //     max: 25,
    //     speed: 400,
    //     glare: true,
    //     "max-glare": 0.8
    // });
    return (
        <div>
            <input type="checkbox" id="ac-ln-menustate" class="ac-ln-menustate" />
            <nav id="ac-localnav" class="no-js ac-localnav-dark ac-localnav-noborder
			" lang="en-US" dir="ltr" data-sticky data-analytics-region="local nav" role="navigation" aria-label="Local">
                <div class="ac-ln-wrapper">
                    <div class="ac-ln-background"></div>
                    <div class="ac-ln-content">
                        <div class="ac-ln-title">
                            <a href="#" data-analytics-title="product index">
                                <img src="./Logo2.png" alt="" height="40px" />
                            </a>
                        </div>
                        <div class="ac-ln-menu">
                            <a href="https://apple.com#ac-ln-menustate" class="ac-ln-menucta-anchor ac-ln-menucta-anchor-open"
                                id="ac-ln-menustate-open" role="button">
                                <span class="ac-ln-menucta-anchor-label">Local Nav Open Menu</span>
                            </a>
                            <a href="https://apple.com#" class="ac-ln-menucta-anchor ac-ln-menucta-anchor-close"
                                id="ac-ln-menustate-close" role="button">
                                <span class="ac-ln-menucta-anchor-label">Local Nav Close Menu</span>
                            </a>
                            <div class="ac-ln-menu-tray">
                                <ul class="ac-ln-menu-items">
                                    <li class="ac-ln-menu-item">
                                        <a href="#main" class="ac-ln-menu-link">Overview</a>
                                    </li>
                                    <li class="ac-ln-menu-item">
                                        <a href="#clients" class="ac-ln-menu-link">Our Clients</a>
                                    </li>
                                    <li class="ac-ln-menu-item">
                                        <a href="#address" class="ac-ln-menu-link">Address</a>
                                    </li>
                                </ul>
                            </div>

                            <div class="ac-ln-actions">

                                <div class="ac-ln-action ac-ln-action-button">
                                    <a class="ac-ln-button" href="#contact" data-analytics-title="buy - ipad pro">Contact Us</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>

            <label id="ac-ln-curtain" for="ac-ln-menustate"></label>
            {/* <script type="text/javascript" src="https://apple.com/ac/localnav/6/scripts/ac-localnav.built.js"></script> */}
            <section class="section section-hero theme-dark" data-anim-scroll-group="Section - Hero" data-analytics-section-engagement="name:hero" id="main">
                <div class="fluid-section-content hero-top">
                    <h1 class="hero-headline typography-hero-headline">Apptech</h1><br />
                    <div class="hero-m2-logo" role="img" aria-label="Supercharged by M2 Chip">
                        <div style={{fontSize: '36px'}} class="hero-m2-logo-copy typography-hero-logo-copy"
                            aria-hidden="true">The Expert of “Mobile App” & “Web-portal” Technology
                        </div>
                    </div>
                </div>

                <div
                    style={{display: 'flex', flexDirection: 'row', marginTop: '-100px', justifyContent: 'space-between', paddingLeft: '120px', paddingRight: '200px'}}>
                    <video width="50%" height="50%" style={{marginTop: '105px'}} autoplay muted loop>
                        <source src="/Mobile.mp4" type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                    <img src="/tab.jpg" alt="" />
                </div>

                <center>
                    <div style={{width: '90vw', textAlign: 'center'}}>
                        <br />
                        <h2 style={{textAlign: 'center', color: '#fff', fontWeight: 100, fontFamily: 'Courgette, cursive'}}>
                            Apptech is a software company which develops custom Mobile Application with backend database
                            support. Apptech provides solution for GPS based Research data collection, Internet-based radio
                            broadcasting service, product or service selling App, with online payment receiving facility and
                            many more using Android Mobile Application.</h2>
                        <br /><br />
                        <ul class="outro-links">

                        </ul>
                    </div>
                    <Button onClick={e => navigate('/pay')} variant="contained">Make Payment</Button>

                </center>
            </section >
            <hr />

                <br /><br /><br /><br /><br /><br />
                <center><u>
                    <h1 style={{color: '#fff'}}>Our Clients</h1>
                </u></center>
                <section class="clients" id="clients">
                    <div class="box">
                        <div class="glass"></div>
                        <div class="content">
                            <center>
                                <h2 style={{color: '#fff'}}>Directorate of Cinchona, GTA, WB</h2><br />
                            </center>
                            <p>Apptech has developed Android App based reporting portal for the Directorate of Cinchona and Other
                                Medicinal Plants, Mungpoo, GTA, West Bengal. The portal has Activity, Resource & Knowledge
                                Management Components. This App is available at Google <a
                                    href="https://play.google.com/store/apps/details?id=com.apptech.cinchona&hl=en-IN"
                                    target="_blank">Playstore</a></p>
                        </div>
                    </div>
                    <div class="box">
                        <div class="glass"></div>
                        <div class="content">
                            <center>
                                <h2 style={{color: '#fff'}}>Darjeeling Krishi Vigyan Kendra</h2><br />
                            </center>
                            <p>Apptech has developed QR code-based, Multilingual Audio Guide System for Darjeeling KVK under UBKV.
                                This Mobile App is available at Google <a
                                    href="https://play.google.com/store/apps/details?id=com.apptech.audioGuideScanner&hl=en-IN"
                                    target="_blank">Playstore</a>. It works in 4 Languages, i.e. English, Hindi, Bengali and Nepali.
                                On scanning a QR Code, it tells about agricultural technology to farming community.</p>
                        </div>
                    </div>
                    <div class="box">
                        <div class="glass"></div>
                        <div class="content">
                            <center>
                                <h2 style={{color: '#fff'}}>ICAR - IARI Kalimpong</h2><br />
                            </center>
                            <p>Apptech has developed Darjeeling Mandarin and Large Cardamom Android App for ICAR-IARI, Kalimpong.
                                This Mobile App is available at Google <a
                                    href="https://play.google.com/store/apps/details?id=com.apptech.darjeelingorange"
                                    target="_blank">Plystore</a>. It is a voice supported App for Scientific Management of
                                Darjeeling Mandarin Orange and Large Cardamom in Kalimpong-Darjeeling Hill region. </p>
                        </div>
                    </div>
                    <div class="box">
                        <div class="glass"></div>
                        <div class="content">
                            <center>
                                <h2 style={{color: '#fff'}}>Regional Research Station, UBKV</h2><br />
                            </center>
                            <p>Apptech has developed Research Databank for Regional Research Station, Uttar Banga Krishi
                                Viswavidyalaya. This Android App is available at Google <a
                                    href="https://play.google.com/store/apps/details?id=com.apptech.researchdatabank&hl=en-IN"
                                    target="_blank">Playstore</a>. It captures various research parameters form farmers along with
                                GPS coordinates. It is used by researchers for GPS and OTP verified data collection.</p>
                        </div>
                    </div>
                    <div class="box">
                        <div class="glass"></div>
                        <div class="content">
                            <center>
                                <h2 style={{color: '#fff', clear: 'both', display: 'inline-block', overflow: 'hidden', whiteSpace: 'nowrap'}}>
                                    Tourism Department, <br />GTA, Govt. of WB</h2><br /><br />
                            </center>
                            <p>Apptech has developed GTA Tourist Register Mobile App, for the Department of Tourism, GTA, West
                                Bengal. This Android App is available at Google <a
                                    href="https://play.google.com/store/apps/details?id=ml.apptech.gtatouristregister&hl=en-IN"
                                    target="_blank">Playstore</a>. It captures tourist data from various Hotel, Homestay and
                                generates report to the Tourism Department as per their requirement.</p>
                        </div>
                    </div>
                    <div class="box">
                        <div class="glass"></div>
                        <div class="content">
                            <center>
                                <h2 style={{color: '#fff', clear: 'both', display: 'inline-block', overflow: 'hidden', whiteSpace: 'nowrap'}}>
                                    Online Guesthouse <br />Booking, DCOMP</h2><br /><br />
                            </center>
                            <p>Apptech provides services for online guesthouse booking of the Directorate of Cinchona and Other
                                Medicinal Plants. The booking website is available <a
                                    href="https://cinchonaguesthouse.web.app/">here.</a>. It is a dedicated booking website for 5
                                units under Directorate of Cinchona and Other Medicinal Plants.</p>
                        </div>
                    </div>
                </section>
                <hr />

                    <section class="address" id="address" style={{display: 'flex', flexDirection: 'column'}}>
                        <br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
                        <div>
                            <div class="address1">
                                <div class="card" id="card2">
                                    <div class="content">
                                        <h3>Office Address</h3>
                                        <p>Geetanjali Complex, Near Dream Theatre, 876 Rishi Road, <br /> 9th mile, Kalimpong, West Bengal, India <br /> Pin Code: 734301</p>
                                        <a
                                            href="https://www.google.com/maps/place/Geetanjali+Complex/@27.0643634,88.4667911,21z/data=!4m8!1m2!2m1!1s876+Risi+Road,+9t+mile,+Kalimpon,+West+Benal+-+734301!3m4!1s0x39e423ef7d87f4f7:0x34862df72740cf35!8m2!3d27.0643634!4d88.4670486">Get
                                            Direction</a>
                                    </div>
                                </div>
                                            <br />
                            </div>
                        </div>
                        <br /><br /><br /><br /><br />
                        <div>
                            <center>
                                <div style={{width: '90vw', textAlign: 'center'}}>
                                    <br />
                                    <u>
                                        <h2
                                            style={{textDecoration: 'underline', alignItems: 'center', color: '#fffebd', fontWeight: 100, fontFamily: 'Courgette, cursive'}}>
                                            We provide 2 months internship for BCA and BBA Students.
                                        </h2>
                                    </u><br /><br />
                                    <i>
                                        <h3
                                            style={{textAlign: 'center', color: '#fff', fontWeight: 100, fontFamily: 'Verdana, Geneva, Tahoma, sans-serif'}}>
                                            What is internship & how it benefits to the students?<br /><br />
                                            An internship is a professional learning experience that offers meaningful, practical work
                                            related to a student's field of study or career interest. Students can work in short-term
                                            real-time project during its development and implementation phase. The stipend of internship
                                            depends on the project and it is typically 2 to 3 thousand per month. Students can also
                                            prepare project report as per the requirement of their Institute and at the end of
                                            internship receive experience certificate.
                                        </h3>
                                    </i>
                                    <br /><br />
                                    <ul class="outro-links">

                                    </ul>
                                </div>
                            </center>
                        </div>
                    </section>
                    <script type="text/javascript" src="/vanilla-tilt.js"></script>





                    <section class="address" id="contact">

                        <div class="address1">
                            <div class="card" id="card1">
                                <div class="content">
                                    <h3>Contact Details</h3>
                                    <p>Phone: &nbsp;&nbsp;&nbsp;&nbsp; <a href="tel:+917001841402">+91 7001841402</a><br />
                                        E-Mail: &nbsp;&nbsp;&nbsp;&nbsp;<a href="mailto:support@apptech.co.in">support@apptech.co.in</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div></div>
                    </section>
                    <div>
                        <center>
                            <p style={{color: "white"}}>Follow us on </p>
                            <a href="https://www.youtube.com/@apptech.kalimpong?sub_confirmation=1" target="_blank"><img src="/youtube-logo.png" style={{height: '90px'}} alt="" /></a>
                        </center>
                    </div>
                <footer id="ac-globalfooter" class="no-js" lang="en-US" dir="ltr" data-analytics-region="global footer"
                    role="contentinfo" aria-labelledby="ac-gf-label">
                    <div class="ac-gf-content">
                        <h2 class="ac-gf-label" id="ac-gf-label">Apptech Footer</h2>


                        <section class="ac-gf-footer">
                            <div class="ac-gf-footer-locale">
                                <a class="ac-gf-footer-locale-link" href="" title="Choose your country or region">India</a>
                            </div>
                            <div class="ac-gf-footer-legal" style={{ marginLeft: '-30px'}}>
                                <div class="ac-gf-footer-legal-copyright">Copyright © 2022-2023 Apptech. All rights reserved.</div>&nbsp;
                                <div style={{ fontSize: '16px'}} class="ac-gf-footer-legal-copyright">Make sure you agree to our <a href="./T&C.html" target="_blank"> Terms & Conditions</a>, <a href="./PP.html">Privacy Policy</a> and <a href="./RCP.html" target="_blank">Refund Policy</a></div>
                            </div>
                        </section>

                        <script type="text/javascript"
                            src="https://apple.com/ac/globalfooter/7/en_US/scripts/ac-globalfooter.built.js"></script>
                        <script type="text/javascript"
                            src="https://apple.com/ac/localeswitcher/4/en_US/scripts/localeswitcher.built.js"></script>
                    </div>
                </footer>
        </div>
    )
}

export default Home


